
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


function useUser() {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return unsub;
  }, []);
  return { user, loading };
}

export default useUser;