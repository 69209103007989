

import React from 'react';
import { useState, useEffect } from 'react';


import { motion } from 'framer-motion';


import { strengthsText } from './../../text/strengths-text.js';


import OpeStrength from './../../images/ope_s.png';
import OpeWeakness from './../../images/ope_w.png';
import CloStrength from './../../images/clo_s.png';
import CloWeakness from './../../images/clo_w.png';

import ConStrength from './../../images/con_s.png';
import ConWeakness from './../../images/con_w.png';
import UncStrength from './../../images/unc_s.png';
import UncWeakness from './../../images/unc_w.png';

import ExtStrength from './../../images/ext_s.png';
import ExtWeakness from './../../images/ext_w.png';
import IntStrength from './../../images/int_s.png';
import IntWeakness from './../../images/int_w.png';

import AgrStrength from './../../images/agr_s.png';
import AgrWeakness from './../../images/agr_w.png';
import DisStrength from './../../images/dis_s.png';
import DisWeakness from './../../images/dis_w.png';

import EmoStrength from './../../images/emo_s.png';
import EmoWeakness from './../../images/emo_w.png';
import NeuStrength from './../../images/neu_s.png';
import NeuWeakness from './../../images/neu_w.png';



const Strengths = ({ trait, level }) => {


  let strengthImage;
  let weaknessImage;

  let strength1;
  let strength2;
  let strength3;

  let weakness1;
  let weakness2;
  let weakness3;


  if ( trait == "o" ){

    if (level < 4) {
      strengthImage = CloStrength;
      weaknessImage = CloWeakness;
  
      strength1 = strengthsText.cloStrength1;
      strength2 = strengthsText.cloStrength2;
      strength3 = strengthsText.cloStrength3;
      weakness1 = strengthsText.cloWeakness1;
      weakness2 = strengthsText.cloWeakness2;
      weakness3 = strengthsText.cloWeakness3;
  
    }
    else {
      strengthImage = OpeStrength;
      weaknessImage = OpeWeakness;
  
      strength1 = strengthsText.opeStrength1;
      strength2 = strengthsText.opeStrength2;
      strength3 = strengthsText.opeStrength3;
      weakness1 = strengthsText.opeWeakness1;
      weakness2 = strengthsText.opeWeakness2;
      weakness3 = strengthsText.opeWeakness3;
    }

  }
  else if ( trait == "c"){
    if (level < 4) {
      strengthImage = UncStrength;
      weaknessImage = UncWeakness;
  
      strength1 = strengthsText.uncStrength1;
      strength2 = strengthsText.uncStrength2;
      strength3 = strengthsText.uncStrength3;
      weakness1 = strengthsText.uncWeakness1;
      weakness2 = strengthsText.uncWeakness2;
      weakness3 = strengthsText.uncWeakness3;
    }
  
    else {
      strengthImage = ConStrength;
      weaknessImage = ConWeakness;
  
      strength1 = strengthsText.conStrength1;
      strength2 = strengthsText.conStrength2;
      strength3 = strengthsText.conStrength3;
      weakness1 = strengthsText.conWeakness1;
      weakness2 = strengthsText.conWeakness2;
      weakness3 = strengthsText.conWeakness3;
    }
  }
  else if (trait == "e"){
    if (level < 4) {
      strengthImage = IntStrength;
      weaknessImage = IntWeakness;
  
      strength1 = strengthsText.intStrength1;
      strength2 = strengthsText.intStrength2;
      strength3 = strengthsText.intStrength3;
      weakness1 = strengthsText.intWeakness1;
      weakness2 = strengthsText.intWeakness2;
      weakness3 = strengthsText.intWeakness3;
  
    }
    else {
      strengthImage = ExtStrength;
      weaknessImage = ExtWeakness;
  
      strength1 = strengthsText.extStrength1;
      strength2 = strengthsText.extStrength2;
      strength3 = strengthsText.extStrength3;
      weakness1 = strengthsText.extWeakness1;
      weakness2 = strengthsText.extWeakness2;
      weakness3 = strengthsText.extWeakness3;
    }
  }
  else if (trait=="a"){
    if (level < 4) {
      strengthImage = DisStrength;
      weaknessImage = DisWeakness;
  
      strength1 = strengthsText.disStrength1;
      strength2 = strengthsText.disStrength2;
      strength3 = strengthsText.disStrength3;
      weakness1 = strengthsText.disWeakness1;
      weakness2 = strengthsText.disWeakness2;
      weakness3 = strengthsText.disWeakness3;
    }
    else {
      strengthImage = AgrStrength;
      weaknessImage = AgrWeakness;
  
      strength1 = strengthsText.agrStrength1;
      strength2 = strengthsText.agrStrength2;
      strength3 = strengthsText.agrStrength3;
      weakness1 = strengthsText.agrWeakness1;
      weakness2 = strengthsText.agrWeakness2;
      weakness3 = strengthsText.agrWeakness3;
    }
  }
  else{

    if (level < 4) {
      strengthImage = NeuStrength;
      weaknessImage = NeuWeakness;
  
      strength1 = strengthsText.neuStrength1;
      strength2 = strengthsText.neuStrength2;
      strength3 = strengthsText.neuStrength3;
      weakness1 = strengthsText.neuWeakness1;
      weakness2 = strengthsText.neuWeakness2;
      weakness3 = strengthsText.neuWeakness3;
  
  
    }
    else {
      strengthImage = EmoStrength;
      weaknessImage = EmoWeakness;
  
      strength1 = strengthsText.staStrength1;
      strength2 = strengthsText.staStrength2;
      strength3 = strengthsText.staStrength3;
      weakness1 = strengthsText.staWeakness1;
      weakness2 = strengthsText.staWeakness2;
      weakness3 = strengthsText.staWeakness3;
    }

  }


  

  return (
    <>

    
      <div className="facet-strengths">
        <div className="flex-divider">
          <motion.div whileHover={
            {
              y: [null, -5, 0],
              transition: { duration: 0.3 },
            }
          }>
            <img className="strength-image" src={strengthImage} alt="logo" />
          </motion.div>
          <div className="strengths-text">
            <div className="strengths-title">
              Strengths:
            </div>
            <div className="strengths-p">
              <div className="strength">
                • {strength1}
              </div>
              <div className="strength">
                • {strength2}
              </div>
              <div className="strength">
                • {strength3}
              </div>
            </div>
          </div>
        </div>


      </div>



      <div className="facet-strengths">
        <div className="flex-divider">
          <motion.div whileHover={
            {
              y: [null, -5, 0],
              transition: { duration: 0.3 },
            }
          }>
            <img className="strength-image" src={weaknessImage} alt="logo" />
          </motion.div>
          <div className="strengths-text">
            <div className="strengths-title">
              Weaknesses:
            </div>
            <div className="strengths-p">
              <div className="strength">
                • {weakness1}
              </div>
              <div className="strength">
                • {weakness2}
              </div>
              <div className="strength">
                • {weakness3}
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>

  );



}

export default Strengths;