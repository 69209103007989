
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg } from 'html-to-image';


import { opeResultsText } from './../text/ope-results-text.js';
import { conResultsText } from './../text/con-results-text.js';
import { extResultsText } from './../text/ext-results-text.js';
import { agrResultsText } from './../text/agr-results-text.js';
import { staResultsText } from './../text/sta-results-text.js';
import { strengthsText } from './../text/strengths-text.js';


import Logo from './../images/logo.png';

import O from './../images/ope.png';
import O2 from './../images/clo.png';
import C from './../images/conc.png';
import C2 from './../images/unc.png';
import E from './../images/ext.png';
import E2 from './../images/int.png';
import A from './../images/agr.png';
import A2 from './../images/dis.png';
import S from './../images/sta.png';
import S2 from './../images/neu.png';

import OpeClo from './../images/ope_clo.png';
import ConUnc from './../images/con_unc.png';
import ExtInt from './../images/ext_int.png';
import AgrDis from './../images/agr_dis.png';
import EmoNeu from './../images/emo_neu.png';


import OpeImage1 from './../images/facet-images/open 1.svg';
import OpeImage2 from './../images/facet-images/open 2.svg';
import OpeImage3 from './../images/facet-images/open 3.svg';

import CloImage1 from './../images/facet-images/clo-1.svg';
import CloImage2 from './../images/facet-images/clo-2.svg';
import CloImage3 from './../images/facet-images/clo-3.svg';

import ConImage1 from './../images/facet-images/con-1.svg';
import ConImage2 from './../images/facet-images/con-2.svg';
import ConImage3 from './../images/facet-images/con-3.svg';

import UncImage1 from './../images/facet-images/unc-1.svg';
import UncImage2 from './../images/facet-images/unc-2.svg';
import UncImage3 from './../images/facet-images/unc-3.svg';

import ExtImage1 from './../images/facet-images/ext-1.svg';
import ExtImage2 from './../images/facet-images/ext-2.svg';
import ExtImage3 from './../images/facet-images/ext-3.svg';

import IntImage1 from './../images/facet-images/int-1.svg';
import IntImage2 from './../images/facet-images/int-2.svg';
import IntImage3 from './../images/facet-images/int-3.svg';

import AgrImage1 from './../images/facet-images/agr-1.svg';
import AgrImage2 from './../images/facet-images/agr-2.svg';
import AgrImage3 from './../images/facet-images/agr-3.svg';

import DisImage1 from './../images/facet-images/dis-1.svg';
import DisImage2 from './../images/facet-images/dis-2.svg';
import DisImage3 from './../images/facet-images/dis-3.svg';

import EmoImage1 from './../images/facet-images/emo-1.svg';
import EmoImage2 from './../images/facet-images/emo-2.svg';
import EmoImage3 from './../images/facet-images/emo-3.svg';

import NeuImage1 from './../images/facet-images/neu-1.svg';
import NeuImage2 from './../images/facet-images/neu-2.svg';
import NeuImage3 from './../images/facet-images/neu-3.svg';



import React from 'react';
import { useState, useEffect } from 'react';


import { Link, useNavigate } from 'react-router-dom';

import ResultChart from './../components/result-chart';
import ResultImage from './../components/result-image';


import Spinner from './../components/spinner';

import Strengths from './results-components/results-strengths.jsx';


import './results.scss';
import './pre-test.scss';
import './../global-variables.scss';


import { motion } from 'framer-motion';


const opeImageText1 = "Unconventional thinker";
const opeImageText2 = "Seeks novelty";
const opeImageText3 = "Interested in aesthetics and ideas";

const cloImageText1 = "Follows convention";
const cloImageText2 = "Practical-minded";
const cloImageText3 = "Fixed interests";

const conImageText1 = "Punctual";
const conImageText2 = "Makes plans";
const conImageText3 = "Organised";

const uncImageText1 = "Misses deadlines";
const uncImageText2 = "Unorganised";
const uncImageText3 = "Sporadic";

const extImageText1 = "Fun-seeking";
const extImageText2 = "Exciteable";
const extImageText3 = "Large social networks";

const intImageText1 = "Seeks alone time";
const intImageText2 = "Quiet";
const intImageText3 = "Small social networks";

const agrImageText1 = "Soft-hearted";
const agrImageText2 = "Conflict-averse";
const agrImageText3 = "Generous";

const disImageText1 = "Blunt";
const disImageText2 = "Doesn't mind conflict";
const disImageText3 = "Competitive";

const staImageText1 = "Self-assured";
const staImageText2 = "Calm";
const staImageText3 = "Optimistic";

const neuImageText1 = "Moody";
const neuImageText2 = "Self-concious";
const neuImageText3 = "Wary";


const Results = ({ personalityData, setModalOpen, loading, isPaid }) => {

  const imageWhileHoverAnimation = {
    y: [null, -5, 0],
    transition: { duration: 0.3 },
  };

  let personalityDataExists = true;

  let opeTitle;
  let opeText1;
  let opeText2;
  let opeText3;

  let conTitle;
  let conText1;
  let conText2;
  let conText3;

  let extTitle;
  let extText1;
  let extText2;
  let extText3;

  let agrTitle;
  let agrText1;
  let agrText2;
  let agrText3;

  let staTitle;
  let staText1;
  let staText2;
  let staText3;

  
  let conAkaText = '';
  let emoAkaText = '';

  let akaText = '';

  let showOpeStrengths = true;
  let showConStrengths = true;
  let showExtStrengths = true;
  let showAgrStrengths = true;
  let showStaStrengths = true;

  let opeImage1;
  let opeImage2;
  let opeImage3;

  let conImage1;
  let conImage2;
  let conImage3;

  let extImage1;
  let extImage2;
  let extImage3;

  let agrImage1;
  let agrImage2;
  let agrImage3;

  let staImage1;
  let staImage2;
  let staImage3;

  let facetOpeImageText1;
  let facetOpeImageText2;
  let facetOpeImageText3;

  let facetConImageText1;
  let facetConImageText2;
  let facetConImageText3;

  let facetExtImageText1;
  let facetExtImageText2;
  let facetExtImageText3;

  let facetAgrImageText1;
  let facetAgrImageText2;
  let facetAgrImageText3;

  let facetStaImageText1;
  let facetStaImageText2;
  let facetStaImageText3;


  if (personalityData == null) {
    personalityData = ({ "ope": 4, "con": 4, "ext": 4, "agr": 4, "sta": 4 });
    personalityDataExists = false;
  }

  const opeLevel = personalityData.ope;
  const conLevel = personalityData.con;
  const extLevel = personalityData.ext;
  const agrLevel = personalityData.agr;
  const staLevel = personalityData.sta;

  //const opeLevel = 2; 
  //const conLevel = 2; 
  //const extLevel = 2; 
  //const agrLevel = 2;
  //const staLevel = 2; 

  if (opeLevel <= 1) {
    opeTitle = "Highly closed to experience";
    opeText1 = opeResultsText.ope1text1;
    opeText2 = opeResultsText.ope1text2;
    opeText3 = opeResultsText.ope1text3;
  }
  else if (opeLevel <= 2) {
    opeTitle = "Moderately closed to experience";
    opeText1 = opeResultsText.ope2text1;
    opeText2 = opeResultsText.ope2text2;
    opeText3 = opeResultsText.ope2text3;
  }
  else if (opeLevel <= 3) {
    opeTitle = "Somewhat closed to experience";
    opeText1 = opeResultsText.ope3text1;
    opeText2 = opeResultsText.ope3text2;
    opeText3 = opeResultsText.ope3text3;
  }
  else if (opeLevel <= 4) {
    opeTitle = "Neither open nor closed to experience";
    opeText1 = opeResultsText.ope4text1;
    opeText2 = opeResultsText.ope4text2;
    opeText3 = opeResultsText.ope4text3;
  }
  else if (opeLevel <= 5) {
    opeTitle = "Somewhat open to experience";
    opeText1 = opeResultsText.ope5text1;
    opeText2 = opeResultsText.ope5text2;
    opeText3 = opeResultsText.ope5text3;
  }
  else if (opeLevel <= 6) {
    opeTitle = "Moderately open to experience";
    opeText1 = opeResultsText.ope6text1;
    opeText2 = opeResultsText.ope6text2;
    opeText3 = opeResultsText.ope6text3;
  }
  else if (opeLevel <= 7) {
    opeTitle = "Highly open to experience";
    opeText1 = opeResultsText.ope7text1;
    opeText2 = opeResultsText.ope7text2;
    opeText3 = opeResultsText.ope7text3;
  }


  if (conLevel <= 1) {
    conTitle = "Highly unstructured";
    conText1 = conResultsText.con1text1;
    conText2 = conResultsText.con1text2;
    conText3 = conResultsText.con1text3;
  }
  else if (conLevel <= 2) {
    conTitle = "Moderately unstructured";
    conText1 = conResultsText.con2text1;
    conText2 = conResultsText.con2text2;
    conText3 = conResultsText.con2text3;
  }
  else if (conLevel <= 3) {
    conTitle = "Somewhat unstructured";
    conText1 = conResultsText.con3text1;
    conText2 = conResultsText.con3text2;
    conText3 = conResultsText.con3text3;
  }
  else if (conLevel <= 4) {
    conTitle = "Neither structured nor unstructured";
    conText1 = conResultsText.con4text1;
    conText2 = conResultsText.con4text2;
    conText3 = conResultsText.con4text3;
  }
  else if (conLevel <= 5) {
    conTitle = "Somewhat structured";
    conText1 = conResultsText.con5text1;
    conText2 = conResultsText.con5text2;
    conText3 = conResultsText.con5text3;
  }
  else if (conLevel <= 6) {
    conTitle = "Moderately structured";
    conText1 = conResultsText.con6text1;
    conText2 = conResultsText.con6text2;
    conText3 = conResultsText.con6text3;
  }
  else if (conLevel <= 7) {
    conTitle = "Highly structured";
    conText1 = conResultsText.con7text1;
    conText2 = conResultsText.con7text2;
    conText3 = conResultsText.con7text3;
  }



  if (extLevel <= 1) {
    extTitle = "Highly introverted";
    extText1 = extResultsText.ext1text1;
    extText2 = extResultsText.ext1text2;
    extText3 = extResultsText.ext1text3;
  }
  else if (extLevel <= 2) {
    extTitle = "Moderately introverted";
    extText1 = extResultsText.ext2text1;
    extText2 = extResultsText.ext2text2;
    extText3 = extResultsText.ext2text3;
  }
  else if (extLevel <= 3) {
    extTitle = "Somewhat introverted";
    extText1 = extResultsText.ext3text1;
    extText2 = extResultsText.ext3text2;
    extText3 = extResultsText.ext3text3;
  }
  else if (extLevel <= 4) {
    extTitle = "Neither introverted nor extraverted";
    extText1 = extResultsText.ext4text1;
    extText2 = extResultsText.ext4text2;
    extText3 = extResultsText.ext4text3;
  }
  else if (extLevel <= 5) {
    extTitle = "Somewhat extraverted";
    extText1 = extResultsText.ext5text1;
    extText2 = extResultsText.ext5text2;
    extText3 = extResultsText.ext5text3;
  }
  else if (extLevel <= 6) {
    extTitle = "Moderately extraverted";
    extText1 = extResultsText.ext6text1;
    extText2 = extResultsText.ext6text2;
    extText3 = extResultsText.ext6text3;
  }
  else if (extLevel <= 7) {
    extTitle = "Highly extraverted";
    extText1 = extResultsText.ext7text1;
    extText2 = extResultsText.ext7text2;
    extText3 = extResultsText.ext7text3;
  }




  if (agrLevel <= 1) {
    agrTitle = "Highly disagreeable";
    agrText1 = agrResultsText.agr1text1;
    agrText2 = agrResultsText.agr1text2;
    agrText3 = agrResultsText.agr1text3;
  }
  else if (agrLevel <= 2) {
    agrTitle = "Moderately disagreeable";
    agrText1 = agrResultsText.agr2text1;
    agrText2 = agrResultsText.agr2text2;
    agrText3 = agrResultsText.agr2text3;
  }
  else if (agrLevel <= 3) {
    agrTitle = "Somewhat disagreeable";
    agrText1 = agrResultsText.agr3text1;
    agrText2 = agrResultsText.agr3text2;
    agrText3 = agrResultsText.agr3text3;
  }
  else if (agrLevel <= 4) {
    agrTitle = "Neither disagreeable nor agreeable";
    agrText1 = agrResultsText.agr4text1;
    agrText2 = agrResultsText.agr4text2;
    agrText3 = agrResultsText.agr4text3;
  }
  else if (agrLevel <= 5) {
    agrTitle = "Somewhat agreeable";
    agrText1 = agrResultsText.agr5text1;
    agrText2 = agrResultsText.agr5text2;
    agrText3 = agrResultsText.agr5text3;
  }
  else if (agrLevel <= 6) {
    agrTitle = "Moderately agreeable";
    agrText1 = agrResultsText.agr6text1;
    agrText2 = agrResultsText.agr6text2;
    agrText3 = agrResultsText.agr6text3;
  }
  else if (agrLevel <= 7) {
    agrTitle = "Highly agreeable";
    agrText1 = agrResultsText.agr7text1;
    agrText2 = agrResultsText.agr7text2;
    agrText3 = agrResultsText.agr7text3;
  }




  if (staLevel <= 1) {
    staTitle = "Highly emotionally variant";
    staText1 = staResultsText.sta1text1;
    staText2 = staResultsText.sta1text2;
    staText3 = staResultsText.sta1text3;
  }
  else if (staLevel <= 2) {
    staTitle = "Moderately emotionally variant";
    staText1 = staResultsText.sta2text1;
    staText2 = staResultsText.sta2text2;
    staText3 = staResultsText.sta2text3;
  }
  else if (staLevel <= 3) {
    staTitle = "Somewhat emotionally variant";
    staText1 = staResultsText.sta3text1;
    staText2 = staResultsText.sta3text2;
    staText3 = staResultsText.sta3text3;
  }
  else if (staLevel <= 4) {
    staTitle = "Neither emotionally stable nor  emotionally variant";
    staText1 = staResultsText.sta4text1;
    staText2 = staResultsText.sta4text2;
    staText3 = staResultsText.sta4text3;
  }
  else if (staLevel <= 5) {
    staTitle = "Somewhat emotionally stable";
    staText1 = staResultsText.sta5text1;
    staText2 = staResultsText.sta5text2;
    staText3 = staResultsText.sta5text3;
  }
  else if (staLevel <= 6) {
    staTitle = "Moderately emotionally stable";
    staText1 = staResultsText.sta6text1;
    staText2 = staResultsText.sta6text2;
    staText3 = staResultsText.sta6text3;
  }
  else if (staLevel <= 7) {
    staTitle = "Highly emotionally stable";
    staText1 = staResultsText.sta7text1;
    staText2 = staResultsText.sta7text2;
    staText3 = staResultsText.sta7text3;
  }


  if (opeLevel <= 4) {

    opeImage1 = CloImage1;
    opeImage2 = CloImage2;
    opeImage3 = CloImage3;

    facetOpeImageText1 = cloImageText1;
    facetOpeImageText2 = cloImageText2;
    facetOpeImageText3 = cloImageText3;

  }
  else {

    opeImage1 = OpeImage1;
    opeImage2 = OpeImage2;
    opeImage3 = OpeImage3;

    facetOpeImageText1 = opeImageText1;
    facetOpeImageText2 = opeImageText2;
    facetOpeImageText3 = opeImageText3;


  }

  if (opeLevel == 4) {
    showOpeStrengths = false;
  }



  if (conLevel <= 4) {
    conAkaText = 'Also known as: Unconscientious';

    conImage1 = UncImage1;
    conImage2 = UncImage2;
    conImage3 = UncImage3;

    facetConImageText1 = uncImageText1;
    facetConImageText2 = uncImageText2;
    facetConImageText3 = uncImageText3;

  }
  else {
    conAkaText = 'Also known as: Conscientious';

    conImage1 = ConImage1;
    conImage2 = ConImage2;
    conImage3 = ConImage3;

    facetConImageText1 = conImageText1;
    facetConImageText2 = conImageText2;
    facetConImageText3 = conImageText3;
  }

  if (conLevel == 4) {
    showConStrengths = false;
    conAkaText = 'Also known as: Conscientious / Unconscientious'
  }


  if (extLevel <= 4) {

    extImage1 = IntImage1;
    extImage2 = IntImage2;
    extImage3 = IntImage3;

    facetExtImageText1 = intImageText1;
    facetExtImageText2 = intImageText2;
    facetExtImageText3 = intImageText3;
  }
  else {

    extImage1 = ExtImage1;
    extImage2 = ExtImage2;
    extImage3 = ExtImage3;

    facetExtImageText1 = extImageText1;
    facetExtImageText2 = extImageText2;
    facetExtImageText3 = extImageText3;
  }

  if (extLevel == 4) {
    showExtStrengths = false;
  }


  if (agrLevel <= 4) {

    agrImage1 = DisImage1;
    agrImage2 = DisImage2;
    agrImage3 = DisImage3;

    facetAgrImageText1 = disImageText1;
    facetAgrImageText2 = disImageText2;
    facetAgrImageText3 = disImageText3;
  }
  else {

    agrImage1 = AgrImage1;
    agrImage2 = AgrImage2;
    agrImage3 = AgrImage3;

    facetAgrImageText1 = agrImageText1;
    facetAgrImageText2 = agrImageText2;
    facetAgrImageText3 = agrImageText3;
  }


  if (agrLevel == 4) {
    showAgrStrengths = false;
  }

  if (staLevel <= 4) {
    emoAkaText = 'Also known as: Neurotic';

    staImage1 = NeuImage1;
    staImage2 = NeuImage2;
    staImage3 = NeuImage3;

    facetStaImageText1 = neuImageText1;
    facetStaImageText2 = neuImageText2;
    facetStaImageText3 = neuImageText3;

  }
  else {
    staImage1 = EmoImage1;
    staImage2 = EmoImage2;
    staImage3 = EmoImage3;

    facetStaImageText1 = staImageText1;
    facetStaImageText2 = staImageText2;
    facetStaImageText3 = staImageText3;

  }

  if (staLevel == 4) {
    emoAkaText = 'Also known as: Emotionally stable / Neurotic'
    showStaStrengths = false;
  }


  let opeLogo;
  let conLogo;
  let extLogo;
  let agrLogo;
  let staLogo;




  // setting correct logos

  if (opeLevel < 4) {
    opeLogo = O2;
   
  }
  else if (opeLevel > 4) {
    opeLogo = O;
  
  }
  else {
    opeLogo = OpeClo;
  
  }

  if (conLevel < 4) {
    conLogo = C2;
    
  }
  else if (conLevel > 4) {
    conLogo = C;
  
  }
  else {
    conLogo = ConUnc;
   
  }

  if (extLevel < 4) {
    extLogo = E2;
  
  }
  else if (extLevel > 4) {
    extLogo = E;
  
  }
  else {
    extLogo = ExtInt;
  
  }

  if (agrLevel < 4) {
    agrLogo = A2;
  
  }
  else if (agrLevel > 4) {
    agrLogo = A;
  
  }
  else {
    agrLogo = AgrDis;
   
  }

  if (staLevel < 4) {
    staLogo = S2;
   
  }
  else if (staLevel > 4) {
    staLogo = S;
   
  }
  else {
    staLogo = EmoNeu;
   
  }




  return (
    <div className="results">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >


        <div className="heading-section" >

          {
          (isPaid == true) 
          ?
           <div> User is paid</div> 
           : <></>
           }

          <div className="flex-divider">
            <img src={Logo} className="bigfive-logo" alt="logo" />
            <div>
              <div className="heading-section-title"> Your results:</div>
            </div>
          </div>
        </div>



      </motion.div>

      {(loading == false && personalityDataExists == true) ?

        <div>


          <ResultsIcons opeLogo={opeLogo} opeTitle={opeTitle} conLogo={conLogo} conTitle={conTitle} extLogo={extLogo} extTitle={extTitle} agrTitle={agrTitle} agrLogo={agrLogo} staLogo={staLogo} staTitle={staTitle} />


          <Facet facetTitle={opeTitle} facetLogo={opeLogo} facetText1={opeText1} facetText2={opeText2} facetText3={opeText3}
            showStrengths={showOpeStrengths} 
            akaText={akaText} level={opeLevel - 1} facetType={"ope"} facetImage1={opeImage1} facetImage2={opeImage2} facetImage3={opeImage3}
            imageText1={facetOpeImageText1} imageText2={facetOpeImageText2} imageText3={facetOpeImageText3}

             trait={"o"} 
          />
          <Facet facetTitle={conTitle} facetLogo={conLogo} facetText1={conText1} facetText2={conText2} facetText3={conText3}
           
            akaText={conAkaText} level={conLevel - 1} facetType={"con"} facetImage1={conImage1} facetImage2={conImage2} facetImage3={conImage3}
            imageText1={facetConImageText1} imageText2={facetConImageText2} imageText3={facetConImageText3}

            trait={"c"} 
          />
          <Facet facetTitle={extTitle} facetLogo={extLogo} facetText1={extText1} facetText2={extText2} facetText3={extText3}
            showStrengths={showExtStrengths} 
            akaText={akaText} level={extLevel - 1} facetType={"ext"} facetImage1={extImage1} facetImage2={extImage2} facetImage3={extImage3}
            imageText1={facetExtImageText1} imageText2={facetExtImageText2} imageText3={facetExtImageText3}

            trait={"e"} 
          />
          <Facet facetTitle={agrTitle} facetLogo={agrLogo} facetText1={agrText1} facetText2={agrText2} facetText3={agrText3}
             showStrengths={showAgrStrengths}
            akaText={akaText} level={agrLevel - 1} facetType={"agr"} facetImage1={agrImage1} facetImage2={agrImage2} facetImage3={agrImage3}
            imageText1={facetAgrImageText1} imageText2={facetAgrImageText2} imageText3={facetAgrImageText3}

            trait={"a"} 
          />
          <Facet facetTitle={staTitle} facetLogo={staLogo} facetText1={staText1} facetText2={staText2} facetText3={staText3}
           showStrengths={showStaStrengths} 
            akaText={emoAkaText} level={staLevel - 1} facetType={"sta"} facetImage1={staImage1} facetImage2={staImage2} facetImage3={staImage3}
            imageText1={facetStaImageText1} imageText2={facetStaImageText2} imageText3={facetStaImageText3}

            trait={"s"} 
          />


          <div className="reset-test-text" onClick={() => { setModalOpen(true) }}>
            Delete results and retake test
          </div>

        </div>

        :




        <div className="flex-center">

          {personalityDataExists == true ?
            <Spinner />
            :

            <div> No personality data exists </div>
          }


        </div>

      }



    </div>
  );
}

const ResultsIcons = ({ opeLogo, opeTitle, conLogo, conTitle, extLogo, extTitle, agrTitle, agrLogo, staLogo, staTitle }) => {

  return (

    <div className="result-icons" id="to-export">

      <motion.div className="result"
        initial={{ opacity: 0, y: -10 }}
        animate={
          { opacity: 1, y: 0, transition: { delay: 0.1 } }}

      >
        <motion.img className="result-icon" src={opeLogo} alt="logo"
          whileHover={
            {
              y: [null, -5, 0],
              transition: { duration: 0.3 },
            }
          }
        />
        <div className="result-text">
          {opeTitle}
        </div>


      </motion.div>
      <motion.div className="result"
        initial={{ opacity: 0, y: -10 }}
        animate={
          { opacity: 1, y: 0, transition: { delay: 0.2 } }}

      >
        <motion.img className="result-icon" src={conLogo} alt="logo"
          whileHover={
            {
              y: [null, -5, 0],
              transition: { duration: 0.3 },
            }
          } />
        <div className="result-text">
          {conTitle}
        </div>

      </motion.div>
      <motion.div className="result"
        initial={{ opacity: 0, y: -10 }}
        animate={
          { opacity: 1, y: 0, transition: { delay: 0.3 } }}
      >
        <motion.img className="result-icon" src={extLogo} alt="logo"
          whileHover={
            {
              y: [null, -5, 0],
              transition: { duration: 0.3 },
            }
          }
        />
        <div className="result-text">
          {extTitle}
        </div>

      </motion.div>
      <motion.div className="result"
        initial={{ opacity: 0, y: -10 }}
        animate={
          { opacity: 1, y: 0, transition: { delay: 0.4 } }}
      >
        <motion.img className="result-icon" src={agrLogo} alt="logo"
          whileHover={
            {
              y: [null, -5, 0],
              transition: { duration: 0.3 },
            }
          } />
        <div className="result-text">
          {agrTitle}
        </div>

      </motion.div>
      <motion.div className="result"
        initial={{ opacity: 0, y: -10 }}
        animate={
          { opacity: 1, y: 0, transition: { delay: 0.5 } }}
      >
        <motion.img className="result-icon" src={staLogo} alt="logo"
          whileHover={
            {
              y: [null, -5, 0],
              transition: { duration: 0.3 },
            }
          }
        />
        <div className="result-text">
          {staTitle}
        </div>

      </motion.div>


    </div>

  )
}


const Facet = ({ facetTitle, facetLogo, facetText1, facetText2, facetText3, showStrengths, akaText, level, facetType, facetImage1, facetImage2, facetImage3, imageText1, imageText2, imageText3,
trait }) => {

  return (
    <div className="facet">
      <div className="facet-title" >
        <img className="facet-title-logo" src={facetLogo} alt="logo" />
        <div>
          {facetTitle}
        </div>
      </div>

      <div className="subheading"> {akaText}</div>
      <div className="facet-result-chart">
        <ResultChart facetType={facetType} level={level} />
      </div>

      {showStrengths == true ?

        <div className="facet-images">
          < ResultImage image={facetImage1} text={imageText1} />
          < ResultImage image={facetImage2} text={imageText2} />
          < ResultImage image={facetImage3} text={imageText3} />

        </div>

        : <></>

      }


      {facetText1}

      <div className="small-vertical-spacer" />
      {facetText2}



      <div className="small-vertical-spacer" />
      <div>
        {facetText3}
      </div>

      { showStrengths==true ? 

      <Strengths trait={trait} level={level} />

      : <></>

    }

    

    </div>

  );

}






export default Results;